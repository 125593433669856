import { render, staticRenderFns } from "./Global.vue?vue&type=template&id=6da7e576&"
import script from "./Global.vue?vue&type=script&lang=ts&"
export * from "./Global.vue?vue&type=script&lang=ts&"
import style0 from "./Global.vue?vue&type=style&index=0&id=6da7e576&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MicroHeatCounterVote: require('/home/cleavr/www.bchollos.es/releases/20240906090546579/components/micro/HeatCounterVote.vue').default,MicroUserImage: require('/home/cleavr/www.bchollos.es/releases/20240906090546579/components/micro/userImage.vue').default,MicroBadge: require('/home/cleavr/www.bchollos.es/releases/20240906090546579/components/micro/Badge.vue').default,BtnDiscount: require('/home/cleavr/www.bchollos.es/releases/20240906090546579/components/btn/Discount.vue').default})
